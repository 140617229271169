const pfx = '?cluster=custom&customUrl=https%3A%2F%2Flocal.validator.agorainfra.dev'
const base = 'https://explorer.solana.com'
export const txLink = (tx: string) => {
  return `${base}/tx/${tx}${pfx}`
}

export const pkLink = (pk: string) => {
  return `${base}/address/${pk}${pfx}`
}

export const tokenLink = (pk: string) => {
  return `${base}/address/${pk}/tokens/${pfx}`
}
