import { createWallet, KinClient, KinTest, Wallet } from '@kin-sdk/client'
import React, { FC, useEffect, useState, VFC } from 'react'
import { AppLayout } from './AppLayout'
import { pkLink, tokenLink } from './kin-utils'
import { AppWalletRequestAirdrop } from './wallet/app-wallet-airdrop'
import { AppWalletCreatePayment } from './wallet/app-wallet-payment'

function App() {
  const [kin] = useState<KinClient>(() => new KinClient(KinTest, { appIndex: 10 }))
  const [wallet, setWallet] = useState<Wallet>()
  const [created, setCreated] = useState<boolean>()
  const [createAccountStatus, setCreateAccountStatus] = useState<string | null>()
  const [createAccountLoading, setCreateAccountLoading] = useState<boolean>(false)
  const [balances, setBalances] = useState<string | null | any>()
  const [balancesLoading, setBalancesLoading] = useState<boolean>(false)
  const [showSecret, setShowSecret] = useState<boolean>(false)

  const generateKeyPair = () => setWallet(createWallet('create'))
  const createAccount = async () => {
    setCreateAccountStatus(null)
    setCreateAccountLoading(true)
    try {
      const [result, error] = await kin.createAccount(wallet?.secret!)
      if (error) {
        console.log('error', error)
        setCreateAccountStatus(error)
        setCreateAccountLoading(false)
      } else {
        setCreated(!!result)
        setCreateAccountStatus(result)
        setCreateAccountLoading(false)
      }
    } catch (e) {
      setCreateAccountStatus('Something went wrong!' + e)
      setCreateAccountLoading(false)
    }
  }
  const getBalance = async () => {
    setBalances(null)
    setBalancesLoading(true)
    try {
      const [result, error] = await kin.getBalances(wallet?.publicKey!)
      if (error) {
        console.log('error', error)
        setBalances(error)
        setBalancesLoading(false)
      } else {
        setBalances(result)
        setBalancesLoading(false)
      }
    } catch (e) {
      setBalances('Something went wrong!' + e)
      setBalancesLoading(false)
    }
  }

  useEffect(() => {
    if (!wallet) {
      generateKeyPair()
    }
  }, [wallet])

  useEffect(() => {
    if (wallet && !created) {
      createAccount().then(() => getBalance())
    }
  }, [created, wallet])

  return (
    <AppLayout>
      <Card title="Wallet">
        {wallet ? (
          <>
            <div className="pb-3">
              <button className="btn btn-lg btn-primary" onClick={getBalance}>
                <i className={'fa fa-refresh ' + (balancesLoading ? 'fa-spin' : '')} />
                <span className="ml-2">Refresh</span>
              </button>
              <button className="btn btn-lg btn-primary ml-3" onClick={() => setShowSecret(!showSecret)}>
                <i className="fa fa-eye" />
                <span className="ml-2">Show Secret</span>
              </button>
            </div>
            <div className="text-monospace">
              <h4 className="my-2">
                <a href={pkLink(wallet.publicKey!)} target="_blank" rel="noreferrer">
                  Address: {wallet.publicKey}
                </a>
              </h4>
              {showSecret ? <h4 className="text-light mb-3">Secret : {wallet.secret}</h4> : null}
            </div>
          </>
        ) : (
          <button className="btn btn-lg btn-primary" onClick={generateKeyPair}>
            Generate Wallet
          </button>
        )}

        {!wallet ? null : (
          <div>
            <Spinner loading={createAccountLoading} message="Creating Account" />
            <div>
              {!created && !createAccountLoading ? (
                <button className="btn btn-lg btn-primary" onClick={createAccount}>
                  Create Account
                </button>
              ) : null}
            </div>
            {createAccountStatus !== wallet.publicKey ? <Result message={createAccountStatus} /> : null}

            {createAccountStatus === wallet.publicKey ? (
              <div>
                <Spinner loading={balancesLoading} message="Get Balance" />
                {Array.isArray(balances) ? (
                  balances.map((item) => (
                    <h3 className="my-3" key={item.account}>
                      <a href={tokenLink(item.account!)} target="_blank" rel="noreferrer">
                        <code>
                          {item?.balance || 0} Kin | {item.account}
                        </code>
                      </a>
                    </h3>
                  ))
                ) : (
                  <Result message={balances} />
                )}
              </div>
            ) : null}
          </div>
        )}
      </Card>

      {wallet && created ? (
        <>
          <Card title="Airdrop">
            <AppWalletRequestAirdrop client={kin} wallet={wallet} done={getBalance} />
          </Card>

          <Card title="Payment">
            <AppWalletCreatePayment client={kin} wallet={wallet} done={getBalance} />
          </Card>
        </>
      ) : null}
    </AppLayout>
  )
}

export default App

export const Spinner: VFC<{ loading: boolean; message: string }> = ({ loading, message }) => {
  return loading ? (
    <h3 className="my-3">
      <i className="fa fa-spinner fa-spin mr-2" />
      {message}
    </h3>
  ) : null
}

export const Card: FC<{ title: string }> = ({ children, title }) => {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h3 className="my-0">{title}</h3>
      </div>
      <div className="card-body">{children}</div>
    </div>
  )
}

export const Result: VFC<{ message?: string | null }> = ({ message }) => {
  return message ? (
    <pre style={{ fontSize: '20' }} className="mt-2">
      {message}
    </pre>
  ) : null
}
