import { KinClient, Wallet } from '@kin-sdk/client'
import React, { useState, VFC } from 'react'

const sleep = (seconds = 1) => new Promise((resolve) => setTimeout(resolve, seconds * 1000))

export interface AppWalletRequestAirdropProps {
  done: (done: boolean) => void
  client: KinClient
  wallet: Wallet
}

export const AppWalletRequestAirdrop: VFC<AppWalletRequestAirdropProps> = ({ done, client, wallet }) => {
  const [requestAirdropEnabled, setRequestAirdropEnabled] = useState(true)
  const [requestAirdropStatus, setRequestAirdropStatus] = useState<any>(null)
  const [destination, setDestination] = useState<string>(wallet?.publicKey!)
  const [amount, setAmount] = useState<string>('1000')
  const [counter, setCounter] = useState<number | null>(0)
  const requestAirdrop = async (drop: string) => {
    setRequestAirdropEnabled(false)
    setRequestAirdropStatus({ status: 'requestAirdrop Started' })
    try {
      const [, error] = await client.requestAirdrop(wallet.publicKey!, drop)
      setRequestAirdropStatus(error ? error : null)
      setRequestAirdropEnabled(true)
      done(true)
    } catch (error) {
      setRequestAirdropStatus({ error })
      setRequestAirdropEnabled(true)
      done(true)
    }
  }
  const tenDrops = async () => {
    for (let i = 0; i < 10; i++) {
      setCounter(i + 1)
      await requestAirdrop('50000')
      await sleep(3)
    }
  }

  return (
    <div>
      <div className="mb-3">
        <input className="form-control" value={destination} onChange={(e) => setDestination(e?.target?.value)} />
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="number"
          max="50000"
          min="1"
          value={amount}
          onChange={(e) => setAmount(e?.target?.value)}
        />
      </div>
      <button
        onClick={() => requestAirdrop(amount)}
        className="btn btn-lg btn-primary"
        disabled={!requestAirdropEnabled}
      >
        Request Airdrop
      </button>
      <button onClick={tenDrops} className="btn btn-lg btn-primary ml-2" disabled={!requestAirdropEnabled}>
        Request 500K ({counter} / 10)
      </button>
      {requestAirdropStatus ? <pre className="mt-3">{JSON.stringify(requestAirdropStatus, null, 2)}</pre> : null}
    </div>
  )
}
